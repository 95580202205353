@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap");

::-webkit-scrollbar {
  display: none;
}

* {
  font-family: "Poppins", sans-serif;
}

input,
textarea,
select {
  font-size: 16px;
}


@keyframes slideInFromBottom {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-from-bottom {
  animation: slideInFromBottom 4s ease-out;
}
